import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import imgHero from "../images/gfx/hero-article.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-2.jpg"

import imgCoach from "../images/coach-blue-blur.jpg"
import imgPassengerFacemask from "../images/facemask-photo-id.jpg"
import imgMobileTicket from "../images/ticket-with-custom-travel-pattern.jpg"
import imgProfileChrisBell from "../images/chris-bell-profile-square.jpg"
import "./index.css";
import "./case-study.css";
import "./article.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Nav from "../components/nav"
import Footer from "../components/footer"
import Contact from "../components/contact"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="What face covering/facemasks mean for home to school transport" />
        <Nav activeTab="blog" />
        <div>
          <div className="case-study">

              <div className="hero-article" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                <h1 className="blog-headline">What face covering means for home-to-school transport</h1>
              </div>

              <div className="mobile-hero-article" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                <h1 className="blog-headline">What face covering means for home-to-school transport</h1>
              </div>

              <div>

                <div className="article">
                  <div className='article-intro article-inner'>
                    <img src={imgPassengerFacemask} alt="" />
                    <p>With the recent announcement by the UK Government that people in England should wear <a className="link" href="https://news.sky.com/story/coronavirus-britons-now-told-to-wear-face-masks-on-public-transport-heres-how-to-make-one-11986567">face coverings on public transport and for enclosed spaces</a>, this presents new challenges for operators who rely on photo IDs to secure their boarding process.</p>
                    <p>In this article we discuss what this could mean for the average school service operator and how to work around these issues with a modern but cost effective approach to bus passes.</p>
                  </div>
                  <div className='article-inner'>
                    <h2>Photo IDs obsolete?</h2>
                    <p>Regardless of whether you still plan to use social distancing on your services, the new advice from the UK Government will encourage passengers to board with face coverings. This however makes the job of accurate photo ID inspection by drivers near impossible.</p>
                    <p>Transport operators are often dismayed by the lack of consistency they get from manual pass inspection. Drivers who are too thorough can slow down boarding and potentially cause conflict with customers but drivers who are lax effectively open up the system to fraud and cost the company revenue.</p>
                    <p>With the adoption of face covering and facemasks each extreme could be exacerbated.</p>

                    <h3>Slower boarding times</h3>
                    <p>Operators unwilling to review their processes can expect slower boarding times. Drivers will be stuck with the unwanted job of spending more time in closer contact with passengers, as they closely inspect half of their uncovered face, against a photo that probably doesn't look like them much anyway.</p>
                    <p>No one wants to risk delays to buses turning up on time and drivers are unlikely to want to add extra time onto their shift.</p>

                    <h3>Customer conflict</h3>
                    <p>The over-eager pass inspector may want a closer look but this comes at the cost of potential arguments with parents, unhappy at close contact and any requests to remove face coverings. It's unsafe and a conflict worth avoiding.</p>

                    <h3>Unsafe pass hygiene</h3>
                    <p>With a recent study finding that COVID-19 can <a className="link" href="https://www.bbc.com/future/article/20200317-covid-19-how-long-does-the-coronavirus-last-on-surfaces">survive on plastic surfaces</a> for up to 3 days, you won't want your drivers handling passes during inspection. Even with PPE such as gloves, there is still the risk of spreading to other surfaces (including other passes).</p>

                    <h3>Lost revenue</h3>
                    <p>Even at the best of times, a minority of passengers will attempt to get a free ride. Now with the system wide open, revenue has never been at more risk.</p>

                    <h2>Bus passes fit for today's climate</h2>
                    <img src={imgMobileTicket} alt="" />
                    <p>ShuttleID uses a simple but modern approach through cost effective technology. Here's why it's time to reassess your attachment to the traditional plastic photo ID card:</p>

                    <h3>Accurate and faster inspection</h3>
                    <p>Our scanning devices tackle the tricky job of boarding passengers. Passengers present their mobile or printed pass, and the scanner weeds out pass sharing, expired passes, passes for different services, and more. All in less than one second.</p>

                    <h3>Safer</h3>
                    <p>ShuttleID reduces interaction between drivers and passengers, as the pass inspection is essentially completed by a device. Drivers only need to intervene when the scanner flags an issue.</p>
                    <p>It also puts more distance into those interactions. Passengers will usually be around a foot away from the device, and the device can be positioned as close or as far away from your driver as required.</p>

                    <h3>Simpler</h3>
                    <p>Traditional plastic photo IDs aren't without their other drawbacks too. They often take a long time to organise and produce; requiring photos to be posted or emailed in, blank cards purchased, time spent collating data and printing passes. Then there's the all too often forgotten or lost pass to be dealt with.</p>
                    <p>ShuttleID instead handles everything digitally meaning everything becomes instant and at low cost. Parents no longer need to pay replacement pass fees as they have 24/7 access.</p>

                    <h3>Data</h3>
                    <p>Not only does ShuttleID remove the burden of pass inspection from your drivers but it gives you data in the back office.</p>
                    <p>Data that can inform you of problems with a passenger, help you calm a worried parent and identify new commercial opportunities to help generate more revenue.</p>

                    <h4>Find out more</h4>
                    <p>If you agree it's time to re-evaluate the plastic photo ID card and want to hear more about ShuttleID contact us at <a className="link" href="mailto:info@shuttleid.uk">info@shuttleid.uk</a>, call 0333 344 9868 or complete our <Link className="link" to="/contact/">contact form</Link> today.</p>

                    <div className='author' style={{ border: '1px solid #d5e6f5', background: '#f3f9ff', marginBottom: '10px' }}>
                      <div className='author__img'>
                        <img src={imgProfileChrisBell} alt="Chris Bell" />
                      </div>
                      <div className='author__quote' style={{  }}>
                        <p style={{ marginBottom: 10 }}><strong>About the author</strong></p>
                        <p style={{ marginBottom: 10 }}>Chris Bell is a director at ShuttleID, a cost effective cashless ticketing system designed to make managing home to school services simple.</p>
                        <p style={{ marginBottom: 10 }}>During a 14 year career in technology, Chris worked on many websites and systems for coach companies and saw the issues of managing home to school first hand, leading to the launch of ShuttleID.</p>
                        <p style={{ margin: 0 }}>Email: <a className="link" href="mailto:info@shuttleid.uk">info@shuttleid.uk</a> | Phone: 0333 344 9868</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage